<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title> Тестирование - {{ data.name }} </template>
      <div></div>
      <v-card v-for="(el, i) in tests" :key="i" width="100%">
        <v-card-title>
          <v-btn @click="onDel(tests, i)" left fab x-small color="red">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          Вопрос №{{ el.numb }}
          <v-spacer />
          <v-btn :disabled="getError()" small color="primary" @click.stop="onAddAnswer(i)"> Добавить ответ </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="mb-2 .flex-nowrap .flex-md-nowrap">
            <v-col cols="0" class="flex-grow-1 flex-shrink-0">
              <v-textarea outlined v-model="el.text" :error="getError('question', { q: i })" label="Текст вопроса" hide-details="auto" rows=" 5" dense />
            </v-col>
            <v-col cols="0" class="flex-grow-1 flex-shrink-0">
              <v-row v-for="(ans, x) in el.answers" :key="x" no-gutters class="ml-2 mb-2 flex-nowrap flex-md-nowrap">
                <v-btn @click="onDel(el.answers, x)" left fab x-small color="red">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-text-field outlined v-model="ans.text" :error="getError('answer', { q: i, a: x })" label="Текст ответа" hide-details="auto" dense />
                <v-checkbox outlined v-model="ans.result" label="верно" hide-details="auto" dense />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="needSave" color="primary" @click.stop="onAddTest(tests)"> Добавить вопрос </v-btn>
        <a-btn-save :disabled="!needSave" @click="onSave()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";

export default {
  mixins: [],
  props: {
    value: Boolean,
    id: Number,
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      api: "/mechti/information",
      model: [{ name: "numb", title: "Номер", type: "number", readonly: true, validator: ["req"] }],
      tests: [],
      needSave1: false,
      errors: {},
      config: {
        type: Object,
        default: () => {
          return { dense: true, hideDetails: "auto" };
        },
      },
    };
  },
  computed: {
    needSave() {
      return JSON.stringify(this.data?.test_data || []) !== JSON.stringify(this.tests);
    },
  },
  watch: {
    "data.test_data": {
      handler(newVal, oldVal) {
        //this.tests = this.data.test_data || [];
      },
      deep: true, // Необходимо указать deep: true, чтобы отслеживать изменения во вложенных свойствах объекта
    },
    value() {
      if (this.value) {
        this.init();
        this.tests = JSON.parse(JSON.stringify(this.data.test_data || []));
      }
    },
    tests: {
      handler(newVal, oldVal) {
        this.needSave1 = true;
        console.log("tests changed", newVal);
        //this.tests = this.data.test_data || [];
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.errors = {};
    },
    onSave() {
      if (this.getError()) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Не верно заполнены поля",
        });
        console.log("try save", this.errors);

        return;
      }
      console.log("try save");
      this.$emit("save", this.tests);
    },
    onInput_dell(e, t, o = {}) {
      if (t == "answer") {
        this.tests[o.q].answers[o.a].text = e;
      } else if (t == "question") {
        this.tests[o.q].text = e;
      } else {
        console.error("Не изветсное изменение объекта");
      }
    },
    getError(t, o = {}) {
      if (t == "answer") {
        return !!!this?.tests?.[o.q]?.answers?.[o.a]?.text;
      } else if (t == "question") {
        return !!!this?.tests?.[o.q]?.text;
      } else {
        for (let n_q = 0; n_q < this.tests.length; n_q++) {
          const q = this.tests[n_q];
          if (this.getError("question", { q: n_q })) return true;
          for (let n_a = 0; n_a < (q?.answers || []).length; n_a++) {
            if (this.getError("answer", { q: n_q, a: n_a })) return true;
          }
        }
      }
      return false;
    },
    onAddTest() {
      let d = this.tests || [];
      d = [...d, { numb: this.tests.length + 1, text: null, answers: [] }];
      this.tests = d;
    },
    onAddAnswer(i) {
      let a = this.tests[i].answers || [];

      a = [...a, { text: null, result: false }];
      this.tests[i].answers = a;
    },
    onDel(el, key) {
      let e = el;
      e.splice(key, 1);
      el = e;
    },
  },
};
</script>
